import { Component, ErrorInfo, ReactNode } from 'react'
import { NotificationWithAction } from '../notification-with-action'
import ErrorImg from '../error-images/error.svg'
import { Translate } from 'next-translate'
import withTranslation from 'next-translate/withTranslation'

interface Props {
  i18n: { t: Translate }
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundaryClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Datadog already intercepts errors so we shouldn't have to do anything specific here.
    // Good for debuggin'.
    //console.log(`ErrorBoundary.componentDidCatch()`, error, errorInfo)
  }

  override render() {
    const { t } = this.props.i18n

    if (this.state.hasError) {
      document.body.classList.add('bg-shades-25')

      return (
        <NotificationWithAction
          imgSrc={ErrorImg}
          imgAlt="Error"
          imgClassName="-ml-6"
          heading={t('ui:errors.headings.oops')}
          message={t('ui:errors.messages.something-sent-wrong')}
          ctaLabel={t('ui:errors.cta-labels.back-to-listings')}
          handleCta={() => {
            // Have to reset this or it will always render an error, regardless of what page we go to.
            this.setState({ hasError: false })

            // Sepcifically don't use router here because it doesn't work as expected in an ErrorBoundary.
            window.location.replace(window.location.origin)
          }}
          className="mt-20"
        />
      )
    }

    return this.props.children
  }
}

export const ErrorBoundary = withTranslation(ErrorBoundaryClass)
