import { twMerge } from 'tailwind-merge'

import { NoOp } from '@unreserved-frontend-v2/utils/noop'

import DefaultSVG from './default-svg.svg'
import { Button, ButtonProps } from '../button/button'
import { FlexCol } from '../flex/flex-col'

export interface NotificationWithActionProps {
  heading: string
  message: string
  imgSrc?: string | React.ElementType
  imgAlt: string
  imgClassName?: string
  ctaLabel?: string
  ctaProps?: ButtonProps
  handleCta?: () => void
  className?: string
  dataTestId?: string
}

/**
 * Component used to display a large notification image along with a title/message and and optional
 * CTA button.
 */
export function NotificationWithAction({
  heading,
  message,
  imgSrc: ImgSrc = DefaultSVG,
  imgAlt,
  imgClassName = '',
  ctaLabel = '',
  handleCta = NoOp,
  className = '',
  dataTestId = '',
  ctaProps,
}: NotificationWithActionProps) {
  // Wanted to keep things simple and not use a render prop for the message. So split it based on \n characters
  // so that we can render a separate <p> for each one.
  const messages = message.split('\n')

  return (
    <FlexCol className={twMerge('mx-6 items-center md:mx-auto', className)} dataTestId={dataTestId}>
      {typeof ImgSrc === 'string' ? (
        <img className={twMerge('mb-7 w-[196px]', imgClassName)} src={ImgSrc} alt={imgAlt} />
      ) : (
        <ImgSrc className={twMerge('mb-7 w-[196px]', imgClassName)} />
      )}
      <h4 className="mb-2 text-xl font-bold">{heading}</h4>
      {messages.map((msg, i) => {
        return (
          <p key={i} className={twMerge('text-center leading-[26px]', `${i === messages.length - 1 ? 'mb-7' : ''}`)}>
            {msg}
          </p>
        )
      })}

      {ctaLabel ? (
        <Button onClick={handleCta} {...ctaProps}>
          {ctaLabel}
        </Button>
      ) : null}
    </FlexCol>
  )
}

export default NotificationWithAction
